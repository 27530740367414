import { Location } from './Location';

export const locationExample = Location.parse({
  locationId: '12345',
  name: 'INARI GLEAN',
  description: 'This is an example store.',
  bannerURL: 'https://picsum.photos/seed/store/400/200',
  timeZone: 'Asia/Tokyo',
  businessHours: '10:00-20:00',
  phoneNumber: '06-6012-3456',
  email: 'mail@example.com',
  // googleMapPlaceId: 'ChIJ-ywKzAvnAGARLXxBWyPsHtw',
  regularHoliday: 'Monday',
  address: '大阪市浪速区稲荷2丁目4-18',
  ownersVoice: 'この店はとても良い店です。',
  access: '地下鉄 千日前線「桜川駅」徒歩5分・阪神なんば線「桜川駅」徒歩8分',
  createdAt: '2021-01-01T00:00:00.000Z',
  updatedAt: '2021-01-01T00:00:00.000Z',
});
